import React, { useState } from "react";
import {
  AppBar,
  Box,
  IconButton,
  Toolbar,
  Typography,
  Link,
  Button,
  CircularProgress,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import helpfulHeroesIcon from "../../assets/icons/hh-icon.svg";
import BottomBar from "../../components/shared/BottomBar";
import ServiceType from "./ServiceType";
import { useNavigate } from "react-router";
import Availability from "./Availability";
import AboutUs from "./AboutUs";
import { LEGAL_LINKS } from "../../utils/contants";
import PropertyType from "./PropertyType";
import ContactInformation from "./ContactInformation";
import { useMutation } from "@tanstack/react-query";
import LeadFormService from "../../services/leadFormService";
import { toast } from "react-toastify";
import { validationSchema } from "./validations";
import { useForm } from "react-hook-form";
import { useYupValidationResolver } from "../../helpers/useValidationResolver";
import HelpfulHeroesTextField from "../../components/shared/HelpfulHeroesTextField";

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: "100vh",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      marginRight: "0.5rem",
      marginLeft: "0.5rem",
    },
  },
  content: {
    flex: 1,
  },
  letsGetStartedContainer: {
    display: "flex",
    flexDirection: "column",
    maxWidth: "33.639rem",
  },
  letsGetStartedSubtitle: {
    marginTop: "0.8rem !important",
    marginBottom: "3rem !important",
    [theme.breakpoints.down("sm")]: {
      maxWidth: "80%",
      alignSelf: "center",
    },
  },
  sectionContainer: {
    marginBottom: "1.7rem",
  },
}));

const defaultValues = {
  firstName: "",
  lastName: "",
  email: "",
  phoneNumber: "",
  description: "",
  addressAttributes: {
    street: "",
    city: "",
    state: "",
    zip: "",
  },
};

const ContactCenter = () => {
  const [locationValue, setLocationValue] = useState("");
  const [locationInputValue, setLocationInputValue] = useState("");
  const classes = useStyles();
  const navigate = useNavigate();
  const resolver = useYupValidationResolver(validationSchema);
  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors },
    watch,
    reset,
  } = useForm({ resolver, defaultValues: defaultValues });

  const { mutate, isPending } = useMutation({
    mutationFn: LeadFormService.submitForm,
    onSuccess: () => {
      toast.success(
        <Typography color="success">
          The project has been submitted successfully
        </Typography>,
        { position: "top-center" }
      );
      reset(defaultValues);
      setLocationValue("");
      setLocationInputValue("");
    },
    onError: () => {
      toast.error(
        <Typography color="error">
          There was a problem, please try again.
        </Typography>,
        { position: "top-center" }
      );
    },
  });

  const onSubmit = (data) => {
    const transformedData = {
      first_name:
        data.firstName.trim() !== "" ? data.firstName.trim() : undefined,
      last_name: data.lastName.trim() !== "" ? data.lastName.trim() : undefined,
      email: data.email.trim() !== "" ? data.email.trim() : undefined,
      phone_number:
        data.phoneNumber.trim() !== "" ? data.phoneNumber.trim() : undefined,
      street:
        data.addressAttributes.street.trim() !== ""
          ? data.addressAttributes.street.trim()
          : undefined,
      city:
        data.addressAttributes.city.trim() !== ""
          ? data.addressAttributes.city.trim()
          : undefined,
      state:
        data.addressAttributes.state.trim() !== ""
          ? data.addressAttributes.state.trim()
          : undefined,
      zip:
        data.addressAttributes.zip.trim() !== ""
          ? data.addressAttributes.zip.trim()
          : undefined,
      service_id: data.serviceType,
      property_type: data.propertyType,
      description:
        data.description.trim() !== "" ? data.description.trim() : undefined,
      availabilities: data.availabilities,
      used_helpful_heroes: data.usedHelpfulHeroes,
      hdyhau: data.aboutUs,
    };
    mutate({ data: JSON.stringify(transformedData) });
  };

  const availabilities = watch("availabilities");
  return (
    <>
      <Box>
        <AppBar position="static">
          <Toolbar>
            <IconButton
              size="large"
              edge="start"
              color="inherit"
              aria-label="menu"
              sx={{ mr: 2 }}
              onClick={() => navigate("/")}
            >
              <img
                alt="helpful heroes logo"
                src={helpfulHeroesIcon}
                width="40px"
              />
            </IconButton>
          </Toolbar>
        </AppBar>
      </Box>
      <div className={classes.root}>
        <div className={classes.content}>
          <div className={classes.letsGetStartedContainer}>
            <Typography variant="h1">Let’s get it done.</Typography>
            <Typography variant="h6" className={classes.letsGetStartedSubtitle}>
              Hire a firefighter for your next home project.
            </Typography>
            <form onSubmit={handleSubmit(onSubmit)}>
              <ContactInformation
                control={control}
                errors={errors}
                setValue={setValue}
                setLocationInputValue={setLocationInputValue}
                locationInputValue={locationInputValue}
                locationValue={locationValue}
                setLocationValue={setLocationValue}
              />
              <ServiceType setValue={setValue} watch={watch} />
              <PropertyType setValue={setValue} watch={watch} />
              <div className={classes.sectionContainer}>
                <Typography sx={{ mb: "0.8rem" }}>
                  Are there any specific details about the job we should know?
                </Typography>
                <HelpfulHeroesTextField
                  multiline
                  controller
                  name="description"
                  control={control}
                  placeholder="Detailed information will make it more likely for your job to be accepted and complete quickly."
                  sx={{
                    width: "100%",
                    fontFamily: "Urbanist-Medium",
                    fontSize: "0.875rem",
                  }}
                />
              </div>
              <Availability
                setValue={(value) => setValue("availabilities", value)}
                availabilities={availabilities}
              />
              <AboutUs setValue={setValue} watch={watch} control={control} />
              <Box>
                <Typography mb={2}>
                  By clicking Submit Project, you agree to the{" "}
                  <Link
                    onClick={() => {
                      window.open(LEGAL_LINKS.TERMS_OF_USE);
                    }}
                    target="_blank"
                    rel="noopener"
                    style={{ textDecoration: "none" }}
                  >
                    Terms of Use
                  </Link>{" "}
                  &{" "}
                  <Link
                    onClick={() => {
                      window.open(LEGAL_LINKS.PRIVACY_POLICY);
                    }}
                    rel="noopener"
                    target="_blank"
                    style={{ textDecoration: "none" }}
                  >
                    Privacy Policy
                  </Link>
                  .
                </Typography>
                <Button
                  type="submit"
                  sx={{ color: "white", width: "100%" }}
                  variant="contained"
                  disabled={isPending}
                  color="navy"
                >
                  {(isPending && (
                    <CircularProgress
                      sx={{
                        color: "#022752",
                        width: "1.5rem !important",
                        height: "1.5rem !important",
                      }}
                    />
                  )) ||
                    "Submit Project"}
                </Button>
              </Box>
            </form>
          </div>
        </div>
        <BottomBar />
      </div>
    </>
  );
};

export default ContactCenter;
